module.exports={ 
    author:"CAVE",
    title:"Cave",
    icon:require("@/assets/icons/logo/CaveIndia/favicon.png"),
    assetpath:'cave',
    pwaData:{
        title:"Cave",
        maskColor:"#ec0f85",
        msColor:"#da532c",
        themeColor:"#ffffff"
      },
    config_path:["https://vlite-config.firebaseio.com/test/web/caveindia/v1.json"],
    _fbId : "1382721665437071",
    _googleId : "533474222306-hqjlglom7v10m42pc4l276e4i2u6jmki.apps.googleusercontent.com",
    _pixelId : "672997713359677",
    _gtmId : 'GTM-KQWSB7S',
    playerConfig:{
        "platform": "vlite",
        "watermark": "CaveIndia",
        "skipTime": 10,
        "progressColor": "#ec0f85",
        "fingerprint":true
      },
    pushConfiguration:[
        ['messagingId','533474222306'],
        ['vendorName','Cave India']
       ],
    colorCodes:{
       "--primary-clr": "#ec0f85",
       "--btn-color": "#ec0f85",
       "--tag-color": "#FE7F9C"
    } ,  
    vendorAssets:'CaveIndia',
    bodyID:"cave-india",
    firebase:{
        primary:{
          apiKey: "AIzaSyAPt1xpDlRTJuh3eo31_26E1RFOmlQz7DE",
          authDomain: "vlite-preprod.firebaseapp.com",
          databaseURL: "https://vlite-preprod.firebaseio.com",
          projectId: "vlite-preprod",
          storageBucket: "vlite-preprod.appspot.com",
          messagingSenderId: "252896885440",
          appId: "1:252896885440:web:4fb95e1c15fc244fd8cb25"
        },
        secondary:{
          apiKey: "AIzaSyD7lCRX2Bovjsd2hzEAYo0vO8d1ASCUrOo",
          authDomain: "cave-india.firebaseapp.com",
          databaseURL: "https://cave-india.firebaseio.com",
          projectId: "cave-india",
          storageBucket: "cave-india.appspot.com",
          messagingSenderId: "533474222306",
          appId: "1:533474222306:web:2b87faf542057759edbebb",
          measurementId: "G-VVDCPN6KSB"
        },
        serverKey: 'AAAAfDWKoOI:APA91bHFJp6wK3leRYsDWeMOz7WI3Z6ucR9HxMz8AJhPX97z2yGyqL2M0r8B4t9Jb2mfZhuL4EQ-2b98ZdEqzmpV1X9kmWzR4DqcFBYZzXLmhSlE-YuzNUQ_EzlIPo1_d8GKXtXYNjxH',
        certificateKey: 'BLBydYQVi5TAnN-BD8ZAcPI_iARJ2xSn_r54LXrl_dKAk0LELP1fyXoDK76DpzQxXdEVWh8RhDcLZ9DR1EqHKcc'
      }
    }